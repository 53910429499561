import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 3;
  background-color: #00000088;
`;

export const HomeLogo = styled.img`
  width: 200px;
`;

export const HomeLogoText = styled.img`
  max-width: 80%;
  width: 69.6rem;
  margin: 3.4rem 0;
`;
