export const EmployeeItems = [
  {
    name: "Lekso Bregvadze",
    position: "Architect",
    image: "https://pro-act-media.b-cdn.net/employee.png",
  },
  {
    name: "Giorgi Jangveladze",
    position: "Constructor",
    image: "https://pro-act-media.b-cdn.net/employee.png",
  },
  {
    name: "Luka Rukhadze",
    position: "Architect",
    image: "https://pro-act-media.b-cdn.net/employee.png",
  },
  {
    name: "Irakli Gavashelishvili",
    position: "Engineer",
    image: "https://pro-act-media.b-cdn.net/employee.png",
  },
];
