import React from "react";

import BackgroundSlider from "../components/background-slider/BackgroundSlider";
import { HomeContainer, PageLayout } from "../components/components";
import HomeContent from "../components/home-content/HomeContent";
import Navigation from "../components/navigation/Navigation";

const Home = (props) => {
  return (
    <PageLayout {...props}>
      <HomeContainer>
        <Navigation />
        <HomeContent />
        <BackgroundSlider />
      </HomeContainer>
    </PageLayout>
  );
};

export default Home;
