import styled from "styled-components";
import RESOLUTIONS from "../../constants/resolutions";
import { FadeIn, SlideIn } from "../components";

export const HeaderContainer = styled.div`
  padding: 9rem 13.6rem 7rem;
  animation: ${FadeIn} 1s linear;
`;

export const HeaderBaseLine = styled.div`
  border-top: 2px solid #000;
  display: flex;
  justify-content: center;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    animation: ${SlideIn} 0.5s linear;
    padding-left: 20rem;
    display: block;
  }
`;

export const Heading = styled.div`
  background: white;
  padding: 0 1.6rem;
  margin-top: -2rem;
  font-size: 3.2rem;
  line-height: 4rem;
  width: fit-content;
  font-family: JosefinSans;
`;
