import React from "react";
import { PageLayout } from "../components/components";
import Footer from "../components/footer/Footer";
import ContactMap from "../components/contact-map/ContactMap";

const Contact = (props) => {
  return (
    <PageLayout {...props}>
      <ContactMap />
      <Footer />
    </PageLayout>
  );
};

export default Contact;
