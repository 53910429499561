import React from "react";
import ICONS from "../../constants/icons";

import { HomeLogo, Container, HomeLogoText } from "./components";

const HomeContent = () => {
  return (
    <>
      <Container>
        <HomeLogo src={ICONS.Logo} alt="Pro Act logo" />
        <HomeLogoText src={ICONS.LogoText} alt="Pro Act" />
      </Container>
    </>
  );
};

export default HomeContent;
