import styled from "styled-components";

export const MapContainer = styled.div`
  width: 100%;
  height: Calc(100vh - 23rem);
  position: relative;
`;

export const MapBlackout = styled.div`
  position: absolute;
  position: 100%;
`;
