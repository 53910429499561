import styled, { keyframes } from "styled-components";

export const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
 `;
export const SlideIn = keyframes`
  0% { padding-left: 0; }
  100% { padding-left: 200; }
 `;

export const Scroll = keyframes`
  0% {
    transform: translate(var(--startPosition));
  }

  100% {
    transform: translate(var(--endPosition));
  }
 `;

export const PageLayout = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  & * {
    /* width */
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const HomeContainer = styled.div`
  background: #000;
`;

export const ArchitectureContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 7vh;
`;

export const ProjectItem = styled.img`
  margin-left: 4px;
  &:first-child {
    margin-left: 0;
  }
`;
