import React, { useCallback, useEffect, useState } from "react";

import { SliderContainer, SliderControls, SliderSwitcher } from "./components";
import { HomeSliderItems } from "../../assets/Configuration/HomeSlider";

const BackgroundSlider = () => {
  const [activeId, setActiveId] = useState(1);
  const [timer, setTimer] = useState();

  const nextBG = useCallback(() => {
    setActiveId((id) => id + 1);
  }, []);

  useEffect(() => {
    const interval = setInterval(nextBG, 5000);
    setTimer(interval);
    return () => clearInterval(interval);
  }, [nextBG]);
  return (
    <>
      {HomeSliderItems.map((bg) => (
        <SliderContainer
          loading="lazy"
          key={bg.id}
          src={bg.url}
          active={activeId % HomeSliderItems.length === bg.id}
        />
      ))}
      <SliderControls>
        {HomeSliderItems.map((bg) => (
          <SliderSwitcher
            key={bg.id}
            active={activeId % HomeSliderItems.length === bg.id}
            onClick={() => {
              setActiveId(bg.id);
              clearInterval(timer);
              const interval = setInterval(nextBG, 5000);
              setTimer(interval);
            }}
          />
        ))}
      </SliderControls>
    </>
  );
};

export default BackgroundSlider;
