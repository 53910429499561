import styled from "styled-components";
import RESOLUTIONS from "../../constants/resolutions";

export const AboutContainer = styled.div`
  flex-grow: 1;
  padding: 2rem;
  @media (width > ${RESOLUTIONS.desktop}) {
    display: grid;
    padding: 2rem 13.6rem 8rem;
    grid-template-columns: 55% 45%;
  }
`;

export const AboutText = styled.div`
  color: #0e0e0e;
  text-align: left;
  font-size: max(2rem, 16px);
  line-height: max(5rem, 40px);
  padding: 2.4rem 2.4rem 7.2rem 2.4rem;
  text-align: left;
  font-family: JosefinSans;
`;

export const EmployeesContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: fit-content;
  @media (max-width: ${RESOLUTIONS.mobile}) {
    grid-template-columns: 100%;
  }
`;

export const Employee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8rem;
  padding: 0 8rem 8rem 8rem;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    margin: 0;
  }
`;

export const Breaker = styled.div`
  height: max(1.5rem, 15px);
`;

export const EmployeePic = styled.img`
  min-width: 120px;
  min-height: 120px;
  width: 60%;
  height: auto;
  border-radius: 50%;
`;

export const EmployeeName = styled.div`
  font-size: max(2rem, 16px);
  padding: 2.4rem;
  text-align: center;
  font-family: JosefinSans;
`;

export const EmployeePosition = styled.div`
  font-size: max(1.8rem, 14px);
  font-family: JosefinSansLight;
`;
