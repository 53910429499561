import React from "react";
import {
  AboutContainer,
  AboutText,
  Breaker,
  Employee,
  EmployeeName,
  EmployeePic,
  EmployeePosition,
  EmployeesContainer,
} from "./components";
import { EmployeeItems } from "../../assets/Configuration/Employees";

const AboutGrid = () => {
  return (
    <AboutContainer>
      <AboutText>
        The design company Pro Act has been operating in the market since 2019 and offers both
        architectural services as well as engineering and fire safety project design.
        <Breaker />
        Our team's goal is to share our many years of experience with our clients and offer them
        tailored services. Along with our experience, effective management allows us to achieve the
        desired results for our clients in a short period of time.
        <Breaker />
        The company successfully collaborates with both individuals and legal entities, designing
        commercial spaces, individual residences, and multi-apartment residential buildings across
        Georgia.
      </AboutText>
      <EmployeesContainer>
        {EmployeeItems.map((empl) => (
          <Employee>
            <EmployeePic src={empl.image} />
            <EmployeeName>{empl.name}</EmployeeName>
            <EmployeePosition>{empl.position}</EmployeePosition>
          </Employee>
        ))}
      </EmployeesContainer>
    </AboutContainer>
  );
};

export default AboutGrid;
