import React from "react";
import { MapContainer } from "./components";

const ContactMap = () => {
  return (
    <MapContainer>
      <iframe
        title="Pro Act"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.7443580297204!2d44.98635041553165!3d41.55313209366647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044012ed40f1799%3A0xeacfeb91f136df8d!2z4YOe4YOg4YOdIOGDlOGDpeGDlyDigKIgUHJvIEFjdA!5e0!3m2!1sen!2sge!4v1677078353879!5m2!1sen!2sge"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullscreen="true"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </MapContainer>
  );
};

export default ContactMap;
