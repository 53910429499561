import styled from "styled-components";
import RESOLUTIONS from "../../constants/resolutions";

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 23rem;
  display: flex;
  flex-direction: column;
`;

export const FooterAddress = styled.div`
  width: 100%;
  height: 17rem;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  padding: 2rem 6rem;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    padding: 2rem 11.5rem;
  }
  font-family: JosefinSans;
`;

export const FooterLinks = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 5rem;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    justify-content: center;
  }
  & > * {
    @media (min-width: ${RESOLUTIONS.tablet}) {
      margin-right: 10rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FooterLinkIcon = styled.img`
  height: 4rem;
  max-width: 48px;
`;
