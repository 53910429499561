export const HomeSliderItems = [
  {
    id: 0,
    url: "https://pro-act-media.b-cdn.net/Arch/1.webp",
  },
  { id: 1, url: "https://pro-act-media.b-cdn.net/Arch/3.webp" },
  {
    id: 2,
    url: "https://pro-act-media.b-cdn.net/Arch/8.webp",
  },
];
