import Logo from "../assets/icons/Logo.svg";
import LogoText from "../assets/icons/LogoText.svg";
import IN from "../assets/icons/social/in.svg";
import YT from "../assets/icons/social/yt.svg";
import FB from "../assets/icons/social/fb.svg";
import IG from "../assets/icons/social/ig.svg";
import TT from "../assets/icons/social/tt.svg";

const ICONS = {
  Logo,
  LogoText,
  IN,
  YT,
  FB,
  IG,
  TT,
};

export default ICONS;
