import styled from "styled-components";
import { Scroll } from "../components";

export const ScrollerContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 31rem;
  margin-bottom: 4px;
  height: ${({ height }) => `${height || 31}vh`};
  font-size: ${({ height }) => (height ? "3vh" : "1.5vh")};
  line-height: ${({ height }) => (height ? "5vh" : "2.5vh")};
  &:hover * {
    animation-play-state: paused;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
`;

export const ScrollerContent = styled.div`
  width: 100%;
  --animationDuration: ${({ quantity }) => `${quantity ? quantity * 7 : 40}s`};
  --startPosition: ${({ reverse }) => (reverse ? "0" : "-100%")};
  --endPosition: ${({ reverse }) => (reverse ? "-100%" : "0")};
  height: 100%;
  display: flex;
  font-family: JosefinSans;
  animation: ${Scroll} var(--animationDuration) linear infinite backwards;
`;
