import React, { useState } from "react";
import { ProjectItem, ProjectItemImage, ProjectItemOverlay } from "./components";

const GalleryItem = ({ src, overlay }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <ProjectItem src={src}>
      <ProjectItemImage
        loading="lazy"
        src={src}
        alt="project"
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
      {!!overlay && <ProjectItemOverlay>{overlay}</ProjectItemOverlay>}
    </ProjectItem>
  );
};

export default GalleryItem;
