import styled from "styled-components";
import RESOLUTIONS from "../../constants/resolutions";
import { FadeIn } from "../components";

export const SliderContainer = styled.img`
  object-fit: cover;
  height: 100vh;
  width: 100%;
  animation: ${FadeIn} 1s ease;
  display: ${({ active }) => (active ? "block" : "none")};
  filter: grayscale(1);
`;

export const SliderControls = styled.div`
  position: absolute;
  display: flex;
  padding: 0.8rem;
  z-index: 10;
  justify-content: center;
  width: 100%;
  top: 6rem;
  right: 0;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    width: fit-content;
    top: 9rem;
    right: 15.2rem;
  }
`;

export const SliderSwitcher = styled.div`
  background: ${({ active }) => (active ? "#fff" : "transparent")};
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 8px;
  height: 16px;
  width: 16px;
`;
