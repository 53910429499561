import React from "react";
import { ArchitectureContainer, PageLayout } from "../components/components";
import Header from "../components/header/Header";
import HorizontalScroller from "../components/horizontal-scroller/HorizontalScroller";
import GalleryItem from "../components/gallery-item/GalleryItem";
import { EngineeringItems } from "../assets/Configuration/Engineering";

const Engineering = (props) => {
  return (
    <PageLayout {...props}>
      <Header heading="engineering" />
      <ArchitectureContainer>
        <HorizontalScroller
          height={62}
          items={EngineeringItems.map((item) => (
            <GalleryItem key={item.id} src={item.url} alt={item.title} overlay={item.title} />
          ))}
        />
      </ArchitectureContainer>
    </PageLayout>
  );
};

export default Engineering;
