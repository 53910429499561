import styled from "styled-components";
import RESOLUTIONS from "../../constants/resolutions";

export const NavigationContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  background: #000;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 7;
  display: none;
  @media (min-width: ${RESOLUTIONS.tablet}) {
    display: flex;
  }
`;

export const NavigationItem = styled.div`
  font-size: 3.2rem;
  color: #fff;
  padding: 1.4rem;
  font-family: Atomosfer;
`;
