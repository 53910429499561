export const ArchitectureItems = [
  {
    id: 1,
    url: "https://pro-act-media.b-cdn.net/Arch/1.webp",
  },
  {
    id: 2,
    url: "https://pro-act-media.b-cdn.net/Arch/2.webp",
  },
  {
    id: 3,
    url: "https://pro-act-media.b-cdn.net/Arch/3.webp",
  },
  {
    id: 4,
    url: "https://pro-act-media.b-cdn.net/Arch/4.webp",
  },
  {
    id: 5,
    url: "https://pro-act-media.b-cdn.net/Arch/5.webp",
  },
  {
    id: 6,
    url: "https://pro-act-media.b-cdn.net/Arch/6.webp",
  },
  {
    id: 7,
    url: "https://pro-act-media.b-cdn.net/Arch/7.webp",
  },
  {
    id: 8,
    url: "https://pro-act-media.b-cdn.net/Arch/8.webp",
  },
  {
    id: 9,
    url: "https://pro-act-media.b-cdn.net/Arch/9.webp",
  },
];
