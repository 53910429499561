const RESOLUTIONS = {
  mobile_small: "320px",
  mobile: "390px",
  tablet: "768px",
  desktop: "1024px",
  hd: "1280px",
  fhd: "1920px",
  K_2: "2560px",
  K_4: "3840px",
};

export default RESOLUTIONS;
