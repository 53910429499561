export const EngineeringItems = [
  {
    id: 1,
    url: "https://pro-act-media.b-cdn.net/Eng/1.webp",
    title: "Fire Fighting",
  },
  {
    id: 2,
    url: "https://pro-act-media.b-cdn.net/Eng/2.webp",
    title: "Utility Infrastructure",
  },
  {
    id: 3,
    url: "https://pro-act-media.b-cdn.net/Eng/3.webp",
    title: "Civil Engineering",
  },
];
