import React from "react";
import AboutGrid from "../components/about-grid/AboutGrid";
import { PageLayout } from "../components/components";

import Header from "../components/header/Header";

const About = (props) => {
  return (
    <PageLayout {...props}>
      <Header heading="about company" />
      <AboutGrid />
    </PageLayout>
  );
};

export default About;
