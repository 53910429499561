import React from "react";
import { HeaderBaseLine, HeaderContainer, Heading } from "./components";

const Header = ({ heading }) => {
  return (
    <HeaderContainer>
      <HeaderBaseLine>
        <Heading>{heading}</Heading>
      </HeaderBaseLine>
    </HeaderContainer>
  );
};

export default Header;
