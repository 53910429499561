import React from "react";
import { ArchitectureContainer, PageLayout } from "../components/components";
import Header from "../components/header/Header";
import HorizontalScroller from "../components/horizontal-scroller/HorizontalScroller";
import GalleryItem from "../components/gallery-item/GalleryItem";
import { ArchitectureItems } from "../assets/Configuration/Architecture";

const Architecture = (props) => {
  const halfLength = Math.ceil(ArchitectureItems.length / 2);
  const ArchitectureItemsFirstHalf = ArchitectureItems.slice(0, halfLength);
  const ArchitectureItemsSecondHalf = ArchitectureItems.slice(halfLength);
  return (
    <PageLayout {...props}>
      <Header heading="architecture" />
      <ArchitectureContainer>
        <HorizontalScroller
          height={31}
          items={ArchitectureItemsFirstHalf.map(({ id, url }) => (
            <GalleryItem key={id} src={url} />
          ))}
        />
        <HorizontalScroller
          height={31}
          reverse={true}
          items={ArchitectureItemsSecondHalf.map(({ id, url }) => (
            <GalleryItem key={id} src={url} />
          ))}
        />
      </ArchitectureContainer>
    </PageLayout>
  );
};

export default Architecture;
