import React from "react";
import ICONS from "../../constants/icons";
import { FooterAddress, FooterContainer, FooterLinkIcon, FooterLinks } from "./components";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterAddress>
        <p>Rustavi, Guramishvili Streeet #7 </p>
        <p>Proact7.20@gmail.com</p>
        <p>+995 557 776 642</p>
      </FooterAddress>
      <FooterLinks>
        <a href="https://www.facebook.com/ProActEngineering" target="_blank" rel="noreferrer">
          <FooterLinkIcon src={ICONS.IN} />
        </a>
        <a href="https://www.youtube.com/watch?v=745dfZuPsNk" target="_blank" rel="noreferrer">
          <FooterLinkIcon src={ICONS.YT} />
        </a>
        <a href="https://www.facebook.com/ProActEngineering" target="_blank" rel="noreferrer">
          <FooterLinkIcon src={ICONS.FB} />
        </a>
        <a href="https://www.instagram.com/pro_act_7.20/" target="_blank" rel="noreferrer">
          <FooterLinkIcon src={ICONS.IG} />
        </a>
        <a href="https://www.facebook.com/ProActEngineering" target="_blank" rel="noreferrer">
          <FooterLinkIcon src={ICONS.TT} />
        </a>
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
