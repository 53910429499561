import styled from "styled-components";

export const ProjectItem = styled.div`
  height: 100%;
  background: #dedede;
  position: relative;
  cursor: pointer;
  & > img {
    filter: grayscale(1);
  }
  &:hover > div {
    opacity: 1;
  }
  &:hover > img {
    filter: unset;
  }
  margin: 0 2px;
`;

export const ProjectItemImage = styled.img`
  height: 100%;
  width: auto;
  user-select: none;
  opacity: ${({ loaded }) => (loaded ? "1" : "0")};
  transition: opacity 1s ease;
`;

export const ProjectItemOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000aa;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 12%;
  text-align: center;
  font-size: 1em;
  line-height: 1.8em;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
`;
