import React from "react";
import URLS from "../../constants/urls";

import { NavigationContainer, NavigationItem } from "./components";

const Navigation = () => {
  return (
    <NavigationContainer>
      <a href={`#${URLS.about}`}>
        <NavigationItem>about</NavigationItem>
      </a>
      <a href={`#${URLS.architecture}`}>
        <NavigationItem>architecture</NavigationItem>
      </a>
      <a href={`#${URLS.engineering}`}>
        <NavigationItem>engineering</NavigationItem>
      </a>
      <a href={`#${URLS.contact}`}>
        <NavigationItem>contact</NavigationItem>
      </a>
    </NavigationContainer>
  );
};

export default Navigation;
