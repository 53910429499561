import React from "react";
import URLS from "../constants/urls";
import About from "./About";
import Architecture from "./Architecture";
import Contact from "./Contact";
import Engineering from "./Engineering";
import Home from "./Home";

const Index = () => {
  return (
    <>
      <Home id={URLS.home} />
      <About id={URLS.about} />
      <Architecture id={URLS.architecture} />
      <Engineering id={URLS.engineering} />
      <Contact id={URLS.contact} />
    </>
  );
};

export default Index;
