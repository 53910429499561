import React from "react";
import useDimensions from "../../hooks/useDimensions";

import { ScrollerContainer, ScrollerContent, Wrapper } from "./components";

const HorizontalScroller = ({ items, reverse, height, overlay }) => {
  const { width } = useDimensions();

  return (
    <ScrollerContainer height={height}>
      <Wrapper>
        <ScrollerContent reverse={reverse} viewportw={width} quantity={items.length}>
          {items}
        </ScrollerContent>
        <ScrollerContent reverse={reverse} viewportw={width} quantity={items.length}>
          {items}
        </ScrollerContent>
        <ScrollerContent reverse={reverse} viewportw={width} quantity={items.length}>
          {items}
        </ScrollerContent>
      </Wrapper>
    </ScrollerContainer>
  );
};

export default HorizontalScroller;
